import makeValidationSchema from './PartyIdentifierMakeValidationSchema'
import React from 'react'
import { components, useTranslation, DataFlattener, DateTimeFormatter } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import PartyMasterKeys from '../../constants/locale/key/PartyMaster'
import { Grid } from '@material-ui/core'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyIdentifierCode: '',
  partyIdentifierNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const partyIdentifierCode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.PARTYIDENTIFIERCODE
    )
    const partyIdentifierNumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.PARTYIDENTIFIER
    )

    return { partyIdentifierCode, partyIdentifierNumber }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyIdentifierCode}>
        <CngCodeMasterAutocompleteField
          name='partyIdentifierCode'
          label={translatedTextsObject.partyIdentifierCode}
          disabled={disabled}
          codeType='PARTY_IDENTIFIER_CODE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyIdentifierNumber}>
        <CngTextField
          name='partyIdentifierNumber'
          label={translatedTextsObject.partyIdentifierNumber}
          disabled={disabled}
          inputProps={{ maxLength: 15 }}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}


function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.regDate = DateTimeFormatter.toClientDate(
    localData.regDate
  );
  return localData;
}

function toServerDataFormat(localData) {
    return DataFlattener.unflatten(localData);
}


const PartyIdentifierFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default PartyIdentifierFormProperties
