import React from 'react'
import { components, BasePage as CngBasePage } from 'cng-web-lib'
import SetUpdatePasswordForm from './SetUpdatePasswordForm'

const {
  button: { },
  form: {
    field: {
    }
  },
} = components

function UpdatePasswordForm({ className, showNotification, ...rest }) {
  return (   
    <CngBasePage
      renderPage={(showSnackbar) => (<SetUpdatePasswordForm showNotification={showSnackbar} />)}
    />
  )
}

export default UpdatePasswordForm
