import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'
import { components, useTranslation, useServices } from 'cng-web-lib'
import Container from '@material-ui/core/Container'
import {useForm, FormProvider } from 'react-hook-form'
import ForgotPasswordCustomButtonSection from './ForgotPasswordCustomButtonSection'
import PasswordUrls from 'src/apiUrls/PasswordUrls'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'
import UserPasswordUrls from 'src/apiUrls/UserPasswordUrls'

const {
  button: { CngSecondaryButton,CngButton },
  CngDialog,
  form: {
    field: {
      CngTextField
    }
  },
} = components

function ForgotPasswordDialog({ showNotification }) {

  const methods = useForm();
  const [openCustomDialog, setOpenCustomDialog] = useState(true)
  const history = useHistory()
  const serverDataRef = useRef()

  const [loading, setLoading] = useState(false)

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  const {
    securedSendRequest
  } = useServices()

  function makeTranslatedTextsObject() {
    let userId = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.USER_ID
    )

    let emailAddress = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.EMAIL_ADDRESS
    )

    let getSecurityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.GET_SECURITY_QUESTION
    )

    let cancel = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CANCEL
    )

    let forgotPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.FORGOT_PASSWORD
    )

    let submit = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SUBMIT_BUTTON
    )

    let submitSuccessMessage = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SUBMIT_SUCCESS_MESSAGE
    )

    let submitErrorMessage = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SUBMIT_ERROR_MESSAGE
    )

    return {
      userId,
      emailAddress,
      getSecurityQuestion,
      cancel,
      forgotPassword,
      submit,
      submitSuccessMessage,
      submitErrorMessage
    }
  }

  function onSubmit(data){
    setLoading(true)
    securedSendRequest.execute(
      'POST',
      UserPasswordUrls.FORGOT_PASSWORD,
      data,
      (response) => {
        console.log(response)
        if (response.status === 200) {
          if (response.data) {
            const errorMessages = response.data.errorMessages

            if (errorMessages!=null && errorMessages.length == 0) {
              showNotification(
                'success',
                translatedTextsObject.submitSuccessMessage
              )
              history.push('/login')
            } else {
              errorMessages.forEach((message) =>
                showNotification('error', message)
              )
            }
          }
        }
        setLoading(false)
      },
      () => {
        showNotification('error', translatedTextsObject.submitErrorMessage)
      }
    )
  }
  function BackToLogin(){
    history.push('/login')
  }
  return (
    <>
      <CngDialog
        dialogContent={
          <FormProvider {...methods} >  
              <Container maxWidth={false}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CngTextField type='text' name='userId' label={translatedTextsObject.userId + " *"} id ='userId' />
                  </Grid>
                  <Grid item xs={12}>
                    <CngTextField type='text' name='emailAddress' label={translatedTextsObject.emailAddress + " *"} id="emailAddress" />
                  </Grid>
                <Grid container justify='space-between' spacing={3}>
                  <Grid item xs='auto'>
                    <CngButton
                      onClick={() => { BackToLogin() }}
                      variant="contained"
                      style={{ justifyContent: 'right' }}
                      color="primary"
                    >
                      {translatedTextsObject.cancel}
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <Grid container spacing={2}>
                      <Grid item xs='auto'>
                        <CngButton
                          onClick={methods.handleSubmit(onSubmit)}
                          variant="contained"
                          style={{ justifyContent: 'left' }}
                          color="primary"
                          shouldShowProgress={loading}
                          disabled={loading}
                        >
                          {translatedTextsObject.submit}
                        </CngButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
              </Container>
          </FormProvider>
        }
        dialogTitle={<Typography variant="h4" color="textPrimary">{translatedTextsObject.forgotPassword}</Typography>}
        open={openCustomDialog}
        fullWidth
        maxWidth='sm'
      />
    </>
  )
}

export default ForgotPasswordDialog
