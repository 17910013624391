import PartyMasterApiUrls from '../../apiUrls/PartyMasterApiUrls'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from '../../paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import PartyMasterViewContent from './PartyMasterViewContent'
import PartyMasterLookupsProvider from '../sbci/lookups/PartyMasterLookupsContext'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props

  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { fetchRecord, deleteRecord } = useServices()

  useEffect(() => {
    fetchRecord.execute(PartyMasterApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.PARTY_MASTER_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  if (!data) {
    return <CircularProgress />
  }

  function handleDeletePartyMaster() {
    deleteRecord.execute(
      PartyMasterApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.PARTY_MASTER_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <PartyMasterLookupsProvider>
          <PartyMasterViewContent
            data={data}
            showNotification={showNotification}
          />
        </PartyMasterLookupsProvider>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.PARTY_MASTER_LIST_VIEW)}
          >
            Back to party master list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            <CngButton
              onClick={() => setConfirmDialog(true)}
              startIcon={<FontAwesomeIcon icon={['fal', 'trash']} size='sm' />}
              variant='outlined'
            >
              Delete
            </CngButton>
            <CngButton
              color='primary'
              startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
              onClick={() => history.push(`${pathMap.PARTY_MASTER}/edit/${id}`)}
            >
              Edit
            </CngButton>
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeletePartyMaster}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
