import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'

const { locale: { key: { CommonValidationMessageKeys } }
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"
  const invalidSpecialCharMessage = "Invalid special character(s) detected."

  const regexAlphaNumericWithSpecialChar = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

  return Yup.object({
    partyidn: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    partytype: Yup.string().required(requiredMessage),
    partyname1: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    partyname2: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    address1: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    address2: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    address3: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    cityname: Yup.string().matches(/^[a-zA-Z ]+$/, "Only alpha values are allowed"),
    statecode: Yup.string().when('countrycode',{
      is: 'CA',
      then: Yup.string().required(requiredMessage),
      otherwise :Yup.string().when('countrycode',{
        is: 'US',
        then: Yup.string().required(requiredMessage)
      })
    }).matches(/^[a-zA-Z]*$/, "Only alpha values are allowed with out space."),
    postalcode: Yup.string().matches(/^[a-zA-Z0-9-., ]*$/,"Cannot contain special characters like ),(,@ etc."),
    countrycode: Yup.string(),
    contactperson: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    contactFirstName: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    contactLastName: Yup.string().matches(regexAlphaNumericSpecChar, invalidSpecialCharMessage),
    telephonenumber: Yup.string().matches(/^[0-9]*$/,"Only numeric values are allowed with out space"),
    dba:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").max(35,"Max 35 characters are allowed"),
    email: Yup.lazy((value) => value === '' ? Yup.string() : Yup.string().nullable().test(
      'emailValidation',
      'Invalid email address or excess 5 email addresses',
      function(value){
        if(value!=undefined && value!=null && value!=''){
          let schema = Yup.string().email();
          const emailArray = value.split(",");
          for (var i = 0; i < emailArray.length; i++) {
            if (!schema.isValidSync(emailArray[i])) {
                return false;
            }
          }
          if(emailArray.length>5){
            return false;
          }
        }
        return true;
      }
    )
  )
    ,
    crNo:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").max(12,"Max 12 characters are allowed"),
    faxNo:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").max(36,"Max 36 characters are allowed"),
    division:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").nullable().max(35,"Max 30 characters are allowed"),
    locPortCode:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").nullable().max(35,"Max 35 characters are allowed"),
    poBoxNumber:Yup.string().matches("^[a-zA-Z0-9]*$","Enter alphanumeric characters.").nullable().max(35,"Max 35 characters are allowed"),
    partyIdentifier: Yup.array(),
    secondaryInfoType: Yup.string().nullable(),
    secondaryInfo: Yup.string().matches(regexAlphaNumericSpecChar, "Please enter alphanumeric values and allowed charter is ,-./:;?=()_@./#&+").nullable(),
  })
}

export default makeValidationSchema
