import React, { useContext, useRef, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import { Chip, Typography, useTheme } from '@material-ui/core'
import pathMap from '../../paths/pathMap'
import Namespace from '../../constants/locale/Namespace'
import PartyMasterKeys from '../../constants/locale/key/PartyMaster'
import PartyMasterApiUrls from '../../apiUrls/PartyMasterApiUrls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import { FileForUserGetPartyId } from '../../common/FileForUserCommon'
import { PartyMasterLookupsContext } from '../sbci/lookups/PartyMasterLookupsContext'

const {
  filter: { EQUAL, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props

  const tableRef = useRef(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, party: null })
  const { getLookupValue } = useContext(PartyMasterLookupsContext)
  const { deleteRecord } = useServices()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.PARTY_MASTER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const partyidn = translate(Namespace.PARTY_MASTER, PartyMasterKeys.PARTYIDN)
    const partytype = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYTYPE
    )
    const partyname1 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME1
    )
    const partyname2 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME2
    )
    const address1 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS1)
    const address2 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS2)
    const cityname = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CITYNAME)
    const countrycode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.COUNTRYCODE
    )
    const active = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ACTIVE)

    return {
      partyidn,
      partytype,
      partyname1,
      partyname2,
      address1,
      address2,
      cityname,
      countrycode,
      active
    }
  }

  const columns = [
    {
      field: 'partyidn',
      title: translatedTextsObject.partyidn
    },
    {
      field: 'partytype',
      title: translatedTextsObject.partytype,
      render: (rowData) => {
        return (
          <Typography component='div' variant='inherit'>
            {rowData.partytype.split(',') // split party code by comma
              .map((type) => getLookupValue('partyType', type)) // get each party code description
              .join(', ') // join back as single string
            }
            <TinyChip label={rowData.partytype} />
          </Typography>
        )
      }
    },
    {
      field: 'partyname1',
      title: translatedTextsObject.partyname1
    },
    {
      field: 'address1',
      title: translatedTextsObject.address1
    },
    {
      field: 'address2',
      title: translatedTextsObject.address2
    },
    {
      field: 'cityname',
      title: translatedTextsObject.cityname
    },
    {
      field: 'countrycode',
      title: translatedTextsObject.countrycode,
      render: (rowData) => getLookupValue('countrycode', rowData.countrycode)
    },
    {
      field: 'active',
      title: translatedTextsObject.active,
      render: (rowData) =>
        rowData.active ? (
          <Chip
            label='Active'
            size='small'
            style={{
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText
            }}
          >
            Active
          </Chip>
        ) : (
          <Chip label='Inactive' size='small' />
        )
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.partyidn,
      type: 'textfield',
      name: 'partyidn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partytype,
      type: 'textfield',
      name: 'partytype',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partyname1,
      type: 'textfield',
      name: 'partyname1',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.address1,
      type: 'textfield',
      name: 'address1',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.address2,
      type: 'textfield',
      name: 'address2',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.cityname,
      type: 'textfield',
      name: 'cityname',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.countrycode,
      type: 'textfield',
      name: 'countrycode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.active,
      type: 'radio',
      name: 'active',
      operatorType: EQUAL,
      options: [
        {
          label: 'Active',
          value: 'true',
          filterValue: {
            value: 'true'
          }
        },
        {
          label: 'Inactive',
          value: 'false',
          filterValue: {
            value: 'false'
          }
        }
      ]
    }
  ]

  function handleDeleteParty() {
    if (confirmDialog.party) {
      deleteRecord.execute(PartyMasterApiUrls.DELETE, confirmDialog.party,
        () => {
          showNotification('success', 'Delete succeeded')
          tableRef.current.performRefresh()
        },
        (error) => console.log(error),
        () => setConfirmDialog({ open: false, party: null })
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.PARTY_MASTER_ADD_VIEW)
            },
            label: 'Create party'
          }
        ]}
        columns={columns}
        compact
        rowActions={[
          {
            label: 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
            onClick: (rowData) => history.push(`${pathMap.PARTY_MASTER}/edit/${rowData.id}`)
          },
          {
            label: 'Delete',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, party: rowData })
          }
        ]}
        exportData={{ url: PartyMasterApiUrls.EXPORT }}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${pathMap.PARTY_MASTER}/edit/${rowData.id}`, { cloneData: rowData })
        }
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, party: null })}
        onCancel={() => setConfirmDialog({ open: false, party: null })}
        onConfirm={handleDeleteParty}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
