import React, { useEffect, useState } from 'react'
import Namespace from '../../constants/locale/Namespace'
import PartyMasterKeys from '../../constants/locale/key/PartyMaster'
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Switch as MuiSwitch,
  Typography,
  withStyles
} from '@material-ui/core'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from '../../common/FileForUserCommon'
import Alert from '../../components/Alert'
import Switch from '../../components/Switch'
import CngSection from '../../components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PartyIdentifierSection from './PartyIdentifierSection'

const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  acctId: "",
  partyidn: "",
  partytype: [],
  partyname1: "",
  partyname2: "",
  address1: "",
  address2: "",
  cityname: "",
  statecode: "",
  postalcode: "",
  countrycode: "",
  contactperson: "",
  contactFirstName: "",
  contactLastName: "",
  telephonenumber: "",
  active: true,
  partyId:"",
  dba:"",
  address3:"",
  email:"",
  crNo:"",
  faxNo:"",
  division:"",
  locPortCode:"",
  poBoxNumber:"",
  secondaryInfoType: "",
  secondaryInfo: "",
  ctpatInd: "N",
  partyIdentifier: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: '8px 16px',
    width: (props) => props.width || theme.breakpoints.values.sm,
    '& .MuiFormControlLabel-root': {
      display: 'flex',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        flexGrow: 1
      }
    }
  }
}))(Paper)

function Fields({ disabled, showNotification, shouldHideMap }) {
  const [formSettings, setFormSettings] = useState({ hideOptionalFields: false })
  const [formSettingsPopover, setFormSettingsPopover] = useState({ anchorEl: null })
  const { translate } = useTranslation(Namespace.PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, watch } = useFormContext()

  const countrycode = watch('countrycode')
  const ctpatInd = watch('ctpatInd')
  const partyIdentifier = watch('partyIdentifier')
  const partyType = watch('partytype');
  var isfParty = ["MF", "SE", "BY", "ST", "LG", "CS", "CN", "IM", "BKP"];

  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      setValue('fileForUserId', fileForUserDetails.fileForUserId)
      setValue('fileForUserPartyId', fileForUserDetails.fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserDetails.fileForUserLoginId)
    }
  }, [])

  function makeTranslatedTextsObject() {
    const partyMaster = translate(Namespace.PARTY_MASTER, PartyMasterKeys.TITLE)
    const partyidn = translate(Namespace.PARTY_MASTER, PartyMasterKeys.PARTYIDN)
    const partytype = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYTYPE
    )
    const partyname1 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME1
    )
    const partyname2 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME2
    )
    const address1 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS1)
    const address2 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS2)
    const cityname = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CITYNAME)
    const statecode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.STATECODE
    )
    const postalcode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.POSTALCODE
    )
    const countrycode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.COUNTRYCODE
    )
    const contactperson = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.CONTACTPERSON
    )
    const telephonenumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.TELEPHONENUMBER
    )
    const active = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ACTIVE)
    const dba = translate(Namespace.PARTY_MASTER, PartyMasterKeys.DBA)
    const address3 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS3)
    const email = translate(Namespace.PARTY_MASTER, PartyMasterKeys.EMAIL)
    const crNo = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CRNO)
    const faxNo = translate(Namespace.PARTY_MASTER, PartyMasterKeys.FAXNo)
    const division = translate(Namespace.PARTY_MASTER, PartyMasterKeys.DIVISION)
    const secondaryInfoType = translate(Namespace.PARTY_MASTER, PartyMasterKeys.SECONDARY_INFO_TYPE)
    const secondaryInfo = translate(Namespace.PARTY_MASTER, PartyMasterKeys.SECONDARY_INFO)

    const locPortCode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.LOCPORTCODE
    )
    const poBoxNumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.POBOXNUMBER
    )
    const ctpatInd = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CAPATIND)
    const partyIdentifier = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.TITLE
    )

    const contactFirstName = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.CONTACT_FIRST_NAME
    )

    const contactLastName = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.CONTACT_LAST_NAME
    )

    return {
      partyMaster,
      partyidn,
      partytype,
      partyname1,
      partyname2,
      address1,
      address2,
      cityname,
      statecode,
      postalcode,
      countrycode,
      contactperson,
      telephonenumber,
      active,
      dba,
      address3,
      email,
      crNo,
      faxNo,
      division,
      locPortCode,
      secondaryInfoType,
      secondaryInfo,
      poBoxNumber,
      ctpatInd,
      partyIdentifier,
      contactFirstName,
      contactLastName
    }
  }
  
	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Typography variant='h4' style={{ fontWeight: 600 }}>
                        {translatedTextsObject.partyMaster}
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>
                          *
                        </Typography>
                        Mandatory fields
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}>
                            <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                              <Typography style={{ fontWeight: 600 }} variant='inherit'>
                                NOTE:&nbsp;
                              </Typography>
                              City, province / state, and postal / zip code are required, if the United States or Canada is the chosen country.
                            </Typography>
                          </Alert>
                        </Grid>
                        <Grid item xs='auto'>
                          <IconButton
                            onClick={(event) =>
                              setFormSettingsPopover({ anchorEl: event.currentTarget })
                            }
                            size='small'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              width={24}
                              height={24}
                            >
                              <FontAwesomeIcon icon={['fal', 'ellipsis']} />
                            </Box>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} shouldHide={shouldHideMap.partytype}>
                      <CngCodeMasterAutocompleteField
                        name='partytype'
                        label={translatedTextsObject.partytype}
                        disabled={disabled}
                        codeType='PARTY_TYPE_MASTER'
                        multiple
                        disableCloseOnSelect
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.partyidn}>
                      <CngTextField
                        name='partyidn'
                        inputProps={{ maxLength: 35 }}
                        label={translatedTextsObject.partyidn}
                        disabled={disabled}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.partyname1}>
                      <CngTextField
                        name='partyname1'
                        inputProps={{ maxLength: 71 }}
                        label={translatedTextsObject.partyname1}
                        disabled={disabled}
                        isRequired
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.partyname2}>
                      <CngTextField
                        name='partyname2'
                        inputProps={{ maxLength: 71 }}
                        label={translatedTextsObject.partyname2}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.dba}>
                      <CngTextField
                        name='dba'
                        inputProps={{ maxLength: 50 }}
                        label={translatedTextsObject.dba}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} shouldHide={shouldHideMap.address1}>
                      <CngTextField
                        name='address1'
                        inputProps={{ maxLength: 175 }}
                        label={translatedTextsObject.address1}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address2 || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='address2'
                        inputProps={{ maxLength: 175 }}
                        label={translatedTextsObject.address2}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address3 || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='address3'
                        inputProps={{ maxLength: 175 }}
                        label={translatedTextsObject.address3}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.cityname}>
                      <CngTextField
                        name='cityname'
                        inputProps={{ maxLength: 35 }}
                        label={translatedTextsObject.cityname}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countrycode}>
                      <CngCountryAutocompleteField
                        name='countrycode'
                        key={countrycode}
                        label={translatedTextsObject.countrycode}
                        disabled={disabled}
                        lookupProps={{
                          label: (record) => {
                            return `${record.code};${record.descriptionEn}`
                          }
                        }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.statecode || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='statecode'
                        inputProps={{ maxLength: 2 }}
                        label={translatedTextsObject.statecode}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.postalcode || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='postalcode'
                        inputProps={{ maxLength: 9 }}
                        label={translatedTextsObject.postalcode}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.crNo || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='crNo'
                        inputProps={{ maxLength: 12 }}
                        label={translatedTextsObject.crNo}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.contactperson || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='contactperson'
                        inputProps={{ maxLength: 35 }}
                        label={translatedTextsObject.contactperson}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.contactFirstName || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='contactFirstName'
                        inputProps={{ maxLength: 13 }}
                        label={translatedTextsObject.contactFirstName}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.contactLastName || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='contactLastName'
                        inputProps={{ maxLength: 20 }}
                        label={translatedTextsObject.contactLastName}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.telephonenumber || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='telephonenumber'
                        inputProps={{ maxLength: 15 }}
                        label={translatedTextsObject.telephonenumber}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.faxNo || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='faxNo'
                        inputProps={{ maxLength: 36 }}
                        label={translatedTextsObject.faxNo}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={12} shouldHide={shouldHideMap.email || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='email'
                        inputProps={{ maxLength: 255 }}
                        label={translatedTextsObject.email}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.division || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='division'
                        inputProps={{ maxLength: 30 }}
                        label={translatedTextsObject.division}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.locPortCode || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='locPortCode'
                        inputProps={{ maxLength: 5 }}
                        label={translatedTextsObject.locPortCode}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.poBoxNumber || formSettings.hideOptionalFields}>
                      <CngTextField
                        name='poBoxNumber'
                        inputProps={{ maxLength: 30 }}
                        label={translatedTextsObject.poBoxNumber}
                        disabled={disabled}
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CngSection title='Other Details'>
                    <Grid container spacing={1}>
                      {partyType && partyType.some((pt) => isfParty.indexOf(pt) > -1) &&
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryInfoType}>
                          <CngCodeMasterAutocompleteField
                            name='secondaryInfoType'
                            label={translatedTextsObject.secondaryInfoType}
                            codeType='ACEISF_SECONDARY_INFO_TYPE'
                            size='small'
                          />
                        </CngGridItem>
                      }
                      {partyType && partyType.some((pt) => isfParty.indexOf(pt) > -1) &&
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryInfo}>
                          <CngTextField
                            name='secondaryInfo'
                            label={translatedTextsObject.secondaryInfo}
                            disabled={disabled}
                            inputProps={{ maxLength: 35 }}
                            onChange={(e) => {
                              setValue('secondaryInfo', e.target.value.toUpperCase())
                            }}
                            size='small'
                          />
                        </CngGridItem>
                      }
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ctpatInd}>
                        <Switch
                          fullWidth
                          checked={ctpatInd === 'Y'}
                          name='ctpatInd'
                          label={translatedTextsObject.ctpatInd}
                          disabled={disabled}
                          onChange={(event) => setValue('ctpatInd', event.target.checked ? 'Y' : 'N')}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
                        <CngSelectField
                          name='active'
                          label={translatedTextsObject.active}
                          disabled={disabled}
                          items={[
                            { text: 'Active', value: true },
                            { text: 'Inactive', value: false }
                          ]}
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </CngSection>
                </Grid>
                <Grid item xs={12}>
                  <CngSection title={translatedTextsObject.partyIdentifier}>
                    <PartyIdentifierSection partyIdentifier={partyIdentifier} />
                  </CngSection>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <FormSettingsPopover
        anchorEl={formSettingsPopover.anchorEl}
        open={formSettingsPopover.anchorEl ? true : false}
        onClose={() => setFormSettingsPopover({ anchorEl: null })}
        settings={formSettings}
        onChangeSettings={(setting) => setFormSettings((prev) => ({ ...prev, ...setting }))}
      />
    </>
	)
}

function FormSettingsPopover(props) {
  const { anchorEl, open, onClose, settings, onChangeSettings } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledPopoverWrapper width={300}>
        <FormControlLabel
          control={
            <MuiSwitch
              checked={settings.hideOptionalFields}
              edge='end'
              onChange={(event) =>
                onChangeSettings({ hideOptionalFields: event.target.checked })
              }
            />
          }
          label='Hide optional fields'
          labelPlacement='start'
        />
      </StyledPopoverWrapper>
    </Popover>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
