import { Grid, Button ,Card, CardContent} from '@material-ui/core'
import sweetalert from 'sweetalert'
import React, { useEffect,useState } from 'react'; 
import { useServices, components, useTranslation} from 'cng-web-lib'
import PasswordUrls from 'src/apiUrls/PasswordUrls';
import { fetchUser } from 'src/views/userprofile/UserProfileService';
import TextField from '@material-ui/core/TextField'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'

const {
  card: {
  },
  form: {
    field: {
    },
  },
  table: {
  },
  CngGridItem,
} = components

function SecurityQuestion ({ className, ...rest }) { 

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let securityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION
    )

    let setSecurityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SET_SECURITY_QUESTION
    )

    let securityQuestionAnswer = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION_ANSWER
    )

    let securityQuestionAnswerUpdated = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION_ANSWER_UPDATED
    )

    let updateSecurityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.UPDATE_SECURITY_QUESTION
    )

    let securityAnswer = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_ANSWER
    )

    let userId = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.USER_ID
    )

    let errorMessage1 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_1
    )

    let errorMessage6 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_6
    )

    let errorMessage7 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_7
    )

    let errorMessage8 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_8
    )

    let errorMessage9 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_9
    )

    return {
      setSecurityQuestion,
      updateSecurityQuestion,
      securityQuestion,
      securityAnswer,
      securityQuestionAnswer,
      securityQuestionAnswerUpdated,
      userId,
      errorMessage1,
      errorMessage6,
      errorMessage7,
      errorMessage8,
      errorMessage9
    }
  }

  const { createRecord,securedSendRequest } = useServices()
  useEffect(() => {
    document.title = translatedTextsObject.setSecurityQuestion
    document.getElementById('submitButton').style.textTransform = "capitalize";
  }, []);

  const [user, setUser] = useState([])
  
  useEffect(() => {
    fetchUser(setUser,securedSendRequest) 
  }, []);
  
  const [state, setState] = useState([])

  function validateSecurityQA(profile) {
      let errors = ''
      if(profile.userId === undefined || profile.userId === ''){
        return translatedTextsObject.errorMessage1
      }
      if(profile.questionSec === undefined || profile.questionSec === ''){
        return translatedTextsObject.errorMessage7
      }
      if(profile.answerSec === undefined || profile.answerSec === ''){
        return translatedTextsObject.errorMessage8
      }
      return errors;
  }

  function setSecurityQA({}) { 
    var userId = document.getElementById('userId').value;
    var questionSec = document.getElementById('questionSec').value;
    var answerSec = document.getElementById('answerSec').value;
    let userDetails = {}
    userDetails.userId = JSON.parse(JSON.stringify(userId))
    userDetails.questionSec = JSON.parse(JSON.stringify(questionSec))
    userDetails.answerSec = JSON.parse(JSON.stringify(answerSec))
    let errors = validateSecurityQA(userDetails)
    if(errors.length > 0){
      sweetalert("Errors: " + errors,"" ,"error");
      let dialog = { subject:'Error', errors, open:true}
      setState({ dialog: dialog });
      return
    }
    if(userId !== null && userId.length > 0) {
      document.getElementById('submitButton').className += " Mui-disabled"
      const data = { "userId" : userId ,"questionSec": questionSec ,"answerSec" : answerSec }
      createRecord.execute(
        PasswordUrls.UPDATE_SECURITY_QUESTION,
        {
          ...data,
        },
        (result) => {
          if(result !== undefined && result !== null){
            var error = result.error
            var status = result.status
            if(status === 'Success'){
              sweetalert({
                title: translatedTextsObject.securityQuestionAnswer,
                text: translatedTextsObject.securityQuestionAnswerUpdated,
                icon: "success",
              }).then(() => {
                window.location.reload()
              })
            } else if (status === 'fail') {
              sweetalert({
                title: translatedTextsObject.errorMessage9,
                text: error,
                icon: "error",
              }).then(() => {
                window.location.reload()
              })
            }
          }
        },
        (error) =>{
          console.log(error)
        }
      )
    } else {
      sweetalert({
        title: translatedTextsObject.securityQuestionAnswer,
        text: translatedTextsObject.errorMessage6,
        icon: "error",
      })
    }
  } 
  
  return ( 
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Card> 
        <CardContent>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>{translatedTextsObject.setSecurityQuestion}</CngGridItem>
        </Grid>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <TextField name="userId" id="userId" variant="outlined" label={translatedTextsObject.userId} inputProps={{maxLength: 50}} disabled ={true} required={true} value={user.loginId ? user.loginId : ''} />
            {/* <TextField name="userId" id="userId" variant="outlined" label={translatedTextsObject.userId} inputProps={{maxLength: 50}} disabled ={false} required={true} /> */}
          </CngGridItem>
        </Grid>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <TextField name="questionSec" id="questionSec" variant="outlined" label={translatedTextsObject.securityQuestion} style={{width: 550}} inputProps={{maxLength: 50}} required={true} />
          </CngGridItem>
        </Grid>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <TextField name="answerSec" id="answerSec" variant="outlined" label={translatedTextsObject.securityAnswer} style={{width: 550}} inputProps={{maxLength: 50}} required={true} />
          </CngGridItem>
        </Grid>
        <Grid container spacing={3} style={{marginTop: '15px'}}>
        </Grid>     
        <CngGridItem style={{marginTop: '50px'}}>
          <Button id="submitButton" className="MuiButtonBase-root MuiButton-root ng-button-filled-primary MuiButton-contained"  variant="outlined" onClick={setSecurityQA}>{translatedTextsObject.updateSecurityQuestion}</Button>
        </CngGridItem>    
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  ); 
} 

export default SecurityQuestion; 