import { Grid, Button ,Card, CardContent, FormHelperText} from '@material-ui/core'
import sweetalert from 'sweetalert'
import React, { useEffect,useState } from 'react'; 
import { useServices, components, useTranslation,constants} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import PasswordUrls from 'src/apiUrls/PasswordUrls';
import { fetchUser } from 'src/views/userprofile/UserProfileService';
import TextField from '@material-ui/core/TextField'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'
import TprApiUrls from 'src/apiUrls/TprApiUrls';

const {
  card: {
    CngSimpleCardHeader
  },
  form: {
    field: {
    },
  },
  table: {
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL, LIKE }
} = constants

function SetPasswordForm ({ className, ...rest }) { 

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let changePassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CHANGE_PASSWORD
    )

    let securityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION
    )

    let userId = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.USER_ID
    )

    let oldPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.PASSWORD
    )

    let newPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.NEW_PASSWORD
    )

    let confirmNewPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CONFIRM_NEW_PASSWORD
    )

    let setNewPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SET_NEW_PASSWORD
    )

    let helpText1 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_1
    )

    let helpText2 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_2
    )

    let helpText3 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_3
    )

    let helpText4 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_4
    )

    let errorMessage1 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_1
    )

    let errorMessage2 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_2
    )

    let errorMessage3 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_3
    )

    let errorMessage4 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_4
    )

    let errorMessage5 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_5
    )

    let errorMessage6 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_6
    )

    let errorMessage15 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_15
    )

    let errorMessage16 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_16
    )

    let errorMessage17 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_17
    )

    return {
      changePassword,
      securityQuestion,
      userId,
      oldPassword,
      newPassword,
      confirmNewPassword,
      setNewPassword,
      helpText1,
      helpText2,
      helpText3,
      helpText4,
      errorMessage1,
      errorMessage2,
      errorMessage3,
      errorMessage4,
      errorMessage5,
      errorMessage6,
      errorMessage15,
      errorMessage16,
      errorMessage17
    }
  }
  const { createRecord,securedSendRequest,fetchRecords } = useServices()
  useEffect(() => {
    document.title = translatedTextsObject.setNewPassword;
    document.getElementById('submitButton').style.textTransform = "capitalize";
  }, []);

  const [user, setUser] = useState([])
  
  useEffect(() => {
    fetchUser(setUser,securedSendRequest)
  }, []);

  const [state, setState] = useState([])
  const history = useHistory()

  function setPassword({}) { 
    var username = document.getElementById('username').value;
    var oldPassword = document.getElementById('oldPassword').value;
    var newPassword = document.getElementById('newPassword').value;
    var confirmNewPassword = document.getElementById('confirmNewPassword').value;
    let userDetails = {}

    function validate(profile) {
      let errors = ''
      
      if(profile.username === undefined || profile.username === ''){
        return translatedTextsObject.errorMessage1
      }
      if(profile.oldPassword === undefined || profile.oldPassword === ''){
        return translatedTextsObject.errorMessage2
      }
      /*if(profile.oldPassword !== "" && profile.oldPassword.length !== 8){
        return translatedTextsObject.errorMessage15
      }*/
      if(profile.newPassword === undefined || profile.newPassword === ''){
        return translatedTextsObject.errorMessage3
      }
      if(profile.newPassword !== "" && profile.newPassword.length < 12){
        return translatedTextsObject.errorMessage16
      }
      if(profile.confirmNewPassword === undefined || profile.confirmNewPassword === ''){
        return translatedTextsObject.errorMessage4
      }
      if(profile.confirmNewPassword !== "" && profile.confirmNewPassword.length < 12){
        return translatedTextsObject.errorMessage17
      }
      if(profile.confirmNewPassword !== profile.newPassword){
        return translatedTextsObject.errorMessage5
      }
      return errors;
    }
    
    userDetails.username = JSON.parse(JSON.stringify(username))
    userDetails.oldPassword = JSON.parse(JSON.stringify(oldPassword))
    userDetails.newPassword = JSON.parse(JSON.stringify(newPassword))
    userDetails.confirmNewPassword = JSON.parse(JSON.stringify(confirmNewPassword))
    let errors = validate(userDetails)
    if(errors.length > 0){
      sweetalert("Errors: " + errors,"" ,"error");
      let dialog = { subject:'Error', errors, open:true}
      setState({ dialog: dialog });
      return
    }
    if(username !== null && username.length > 0) {
    
      document.getElementById('submitButton').className += " Mui-disabled"
      const data = { "username" : username ,"oldPassword": oldPassword ,"newPassword" : newPassword , "confirmNewPassword": confirmNewPassword }
      createRecord.execute(
        PasswordUrls.CHANGE_PASSWORD,
        {
          ...data,
        },
        (result) => {
          if(result !== undefined && result !== null){
            var error = result.error
            var status = result.status
            var code = result.code
            if(status === 'Success' && code === '200'){      
              
              fetchRecords.execute(
                TprApiUrls.GET_USER,
                {
                  filters: [
                    {
                      field: 'loginId',
                      operator: EQUAL,
                      value: username
                    }
                  ]
                },
                (res) => {
                  if (res.content.length > 0) {
                    let userDetails = res.content[0];
                    userDetails.firstLogin = false

                    function onSuccess(response){
                      sweetalert({
                        title: translatedTextsObject.changePassword,
                        text: translatedTextsObject.helpText3 + username+","+ translatedTextsObject.helpText4,
                        icon: "success",
                      }).then(() => {
                        history.push('/login')
                      })
                    }

                    function onError(error) {
                      console.log(error)
                    }

                    function onComplete() {

                    }

                    const config = {}
                    securedSendRequest.execute('PUT', TprApiUrls.UPDATE_USER, userDetails, onSuccess, onError, onComplete, config, null);
                  }
                })
            } else if (status === 'fail') {
              sweetalert({
                title: translatedTextsObject.changePassword,
                text: error,
                icon: "error",
              }).then(() => {
                window.location.reload()
              })
            }
          }
        },
        (error) =>{
          console.log(error)
        }
      )
    } else {
      sweetalert({
        title: translatedTextsObject.changePassword,
        text: translatedTextsObject.errorMessage6,
        icon: "error",
      })
    }
  } 
  return ( 
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card> 
          <CngSimpleCardHeader title={translatedTextsObject.changePassword}/>
            <CardContent>
              <Grid container spacing={2}>
                <CngGridItem xs={12} sm={12}>
                  <TextField name="username" id="username" variant="outlined" label={translatedTextsObject.userId} inputProps={{maxLength: 50}} disabled ={true} required={true} value={user.loginId ? user.loginId : ''} />
                  {/* <TextField name="username" id="username" variant="outlined" label={translatedTextsObject.userId} inputProps={{maxLength: 50}} disabled ={false} required={true} /> */}
                </CngGridItem>
              </Grid>
              <Grid container spacing={2}>
                <CngGridItem xs={12} sm={12}>
                  <TextField name="oldPassword" id="oldPassword" type="password" variant="outlined" label={translatedTextsObject.oldPassword} required={true} />
                </CngGridItem >
              </Grid>
              <Grid container spacing={2}>
                <CngGridItem xs={12} sm={12}>
                  <TextField name="newPassword" id="newPassword" type="password" variant="outlined" label={translatedTextsObject.newPassword} inputProps={{maxLength: 24}} required={true} />
                  <FormHelperText>{translatedTextsObject.helpText1}</FormHelperText>
                  <FormHelperText>{translatedTextsObject.helpText2}</FormHelperText>
                </CngGridItem>
              </Grid>
              <Grid container spacing={2}>
                <CngGridItem xs={12} sm={12}>
                  <TextField name="confirmNewPassword" id="confirmNewPassword" type ="password" variant="outlined" label={translatedTextsObject.confirmNewPassword} inputProps={{maxLength: 24}} required={true} />
                </CngGridItem>
              </Grid>
              <CngGridItem style={{marginTop: '50px'}}>
                <Button id="submitButton" 
                    variant="contained" 
                    style={{justifyContent: 'center'}}
                    onClick={setPassword}
                    color="primary">
                  {translatedTextsObject.setNewPassword}
                </Button>
              </CngGridItem>
            </CardContent>
          </Card>
      </Grid>
    </Grid>
  ); 
} 

export default SetPasswordForm; 