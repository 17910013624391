import PartyMasterApiUrls from '../../apiUrls/PartyMasterApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from '../../paths/pathMap'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import withFormState from '../../components/aciacehighway/hocs/withFormState'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Card>
      <Box p={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: PartyMasterApiUrls.POST,
            successRedirect: pathMap.PARTY_MASTER_LIST_VIEW
          }}
          renderButtonSection={(loading) => (
            <PartyMasterAddPageButtonSection history={history} loading={loading} />
          )}
        />
      </Box>
    </Card>
  )
}

function PartyMasterAddPageButtonSection(props) {
  const { history, loading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={loading}
            type='submit'
            size='medium'
          >
            Save
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.PARTY_MASTER_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
