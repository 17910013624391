import { Box, Card, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import PartyMasterApiUrls from 'src/apiUrls/PartyMasterApiUrls'
import CrewMasterApiUrls from 'src/apiUrls/CrewMasterApiUrls'
import EquipmentMasterApiUrls from 'src/apiUrls/EquipmentMasterApiUrls'
import ConveyanceMasterApiUrls from 'src/apiUrls/ConveyanceMasterApiUrls'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  form: {
    field: { CngSelectField }
  }
} = components

const schema = Yup.object({
  messageType: Yup.string().required('This is required'),
  file: Yup.array().min(1)
})

function UploadMasterData({ showNotification }) {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const methods = useForm({
    defaultValues: { messageType: 'PM', module: 'ACEHWY', file: [] },
    resolver: useCustomYupValidationResolver(schema)
  })
  const file = methods.watch('file')
  const { errors } = methods.formState

  const { securedSendRequest } = useServices()

  function handleUploadFile(data) {
    const { messageType, file = [], module } = data
    setLoading(true)

    function getUrl(messageType) {
      switch (messageType) {
        case 'PM':
          return PartyMasterApiUrls.UPLOAD
        case 'CM':
          return CrewMasterApiUrls.UPLOAD
        case 'EM':
          return EquipmentMasterApiUrls.UPLOAD
        case 'VM':
          return ConveyanceMasterApiUrls.UPLOAD
      }
    }

    const fileForUserDetails = FileForUserGetUserDetails()
    const formData = new FormData()

    formData.append('fileForUserDetails', fileForUserDetails ? JSON.stringify(fileForUserDetails) : '{}')
    formData.append('file', file[0].file)
    formData.append('module', module)

    securedSendRequest.execute('POST', getUrl(messageType), formData,
      (response) => {
        if (response.status === 200) {
          const { errorMessages } = response.data

          if (!errorMessages) {
            showNotification('success', 'File has been uploaded successfully')
          } else {
            setErrorMessages(errorMessages)
          }
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Error while uploading file')
      },
      () => {
        setLoading(false)
        methods.setValue('file', [])
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  return (
    <Card>
      <Box padding={2}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CngSelectField
                    name='messageType'
                    label='Message Type'
                    items={[
                      { text: 'Party Master', value: 'PM' },
                      { text: 'Crew Master', value: 'CM' },
                      { text: 'Equipment Master', value: 'EM' },
                      { text: 'Conveyance Master', value: 'VM' }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CngSelectField
                    name='module'
                    label='Module'
                    items={[
                      { text: 'ACE Highway', value: 'ACEHWY' },
                      { text: 'ACI Highway', value: 'ACIHWY' }
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngFileUpload
                accept={['.csv']}
                maxFiles={1}
                maxSize={6291456}
                files={file}
                onFileSelect={(file) => methods.setValue('file', file)}
                showFormFields={false}
                onDropRejected={(message) => showNotification('error', message)}
                moreActions={[
                  { action: 'remove', name: 'Remove', icon: ['fal', 'trash'] }
                ]}
              />

              {errors?.file && (<Typography color='error' variant='body2'>{errors.file.message}</Typography>)}

              <ErrorMessageSection errorMessages={errorMessages} />

            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item xs='auto'>
                  <CngButton
                    disabled={!file || file.length == 0 || loading}
                    onClick={methods.handleSubmit(handleUploadFile)}
                  >
                    Upload
                  </CngButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </Card>
  )
}

export default UploadMasterData
