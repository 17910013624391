import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PartyConfigKeys from 'src/constants/locale/key/PartyConfig'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import TprPartyAutoCompleteField from 'src/components/na/autocomplete/codemaintenance/TprPartyAutoCompleteField'
import CngSection from '../../components/cngcomponents/CngSection'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSwitchField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
  module: "",
  filterByUserFlag: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.PARTY_CONFIG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let partyConfig = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.TITLE
    )
    let partyId = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.PARTY_ID
    )
    let module = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.MODULE
    )
    let filterByUserFlag = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.FILTER_BY_USER_FLAG
    )

    return {
      partyConfig,
      partyId,
      module,
      filterByUserFlag
    }
  }

	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <CngSection title={translatedTextsObject.partyConfig}
                        subheader={
                            <Typography color='textSecondary' variant='caption'>
                                <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>
                        }>
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <TprPartyAutoCompleteField
                required
                name="partyId"
                label={translatedTextsObject.partyId}
                disabled={disabled}
                lookupProps={{
                  label: (record) => {
                      return `${record.id};${record.name}`
                  }
              }}
              />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.module}>
             <CngCodeMasterAutocompleteField
              required
              name="module"
              label={translatedTextsObject.module}
              disabled={disabled}
              codeType='PARTY_CONFIG_MODULE'
            /> 
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.filterByUserFlag}>
            <CngSwitchField
              name="filterByUserFlag"
              label={translatedTextsObject.filterByUserFlag}
              disabled={disabled}
            />
          </CngGridItem>
          </Grid>
        </Grid>
        </Grid>
        </CngSection>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  if(localData.filterByUserFlag == "true"){
    localData.filterByUserFlag = true;
  }else{
    localData.filterByUserFlag = false;
  }

  return localData;
}

function toServerDataFormat(localData) {
  if(localData.filterByUserFlag == true){
    localData.filterByUserFlag = "true";
  }else{
    localData.filterByUserFlag = "false";
  }
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
