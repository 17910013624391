import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import SetPasswordForm from 'src/views/password/SetPasswordForm'
import SecurityQuestion from 'src/views/password/SecurityQuestion'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'

const { CngTabs } = components

function PasswordForm() {

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let changePassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CHANGE_PASSWORD
    )

    let securityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION
    )

    return {
      changePassword,
      securityQuestion
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <SetPasswordForm />
        {/*<CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.changePassword,
              tabContent: (
                <SetPasswordForm />
              )
            },
            {
              tabName: translatedTextsObject.securityQuestion,
              tabContent: (
                <SecurityQuestion />
              )
            }
          ]}
        />*/}
      </Grid>
    </Grid>
  );
}    

export default PasswordForm