import PartyConfigApiUrls from 'src/apiUrls/PartyConfigApiUrls'
import FormProperties from './FormProperties'
import { Box, Card,Grid } from '@material-ui/core'
import React, {useRef, useState} from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {

  const onSuccessCallback = useRef(() =>
        history.push(pathMap.PARTY_CONFIG_LIST_VIEW)
    )

    function handleSaveAndContinue() {
        onSuccessCallback.current = (e) => {
            history.push(`${pathMap.PARTY_CONFIG}/edit/${e.id}`)
        }
    }

  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: PartyConfigApiUrls.POST,
            onSuccess: (e) => {
              showNotification('success', 'Party Config has been created.')
              onSuccessCallback.current(e)
           }
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
                history={history}
                loading={loading}
                onSaveAndContinue={handleSaveAndContinue}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function ButtonSection(props) {
  const { history, loading, onSaveAndContinue } = props
  const [confirmDialog, setConfirmDialog] = useState(false)

  return (
      <>
          <Grid container justify='space-between' spacing={3}>
              <Grid item xs='auto'>
                  <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>Discard</CngButton>
              </Grid>
              <Grid item xs='auto'>
                  <Grid container spacing={2}>
                      <Grid item xs='auto'>
                          <CngButton color='primary' disabled={loading} type='submit' onClick={onSaveAndContinue} size='medium'>Save</CngButton>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <AlertDialog
              cancelLabel='Continue editing'
              confirmLabel='Yes, discard'
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onCancel={() => setConfirmDialog(false)}
              onConfirm={() => history.push(pathMap.PARTY_CONFIG_LIST_VIEW)}
              title='Discard'
          >
              All progress in this session will be lost and cant't be restored. Are
              you sure about this?
          </AlertDialog>
      </>
  )
}

export default AddPage
