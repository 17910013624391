import React from 'react'
import { components, BasePage as CngBasePage } from 'cng-web-lib'
import ForgotPasswordDialog from './ForgotPasswordDialog'

const {
  button: { },
  form: {
    field: {
    }
  },
} = components

function ForgotPasswordForm({ className, showNotification, ...rest }) {
  return (   
    <CngBasePage
      renderPage={(showSnackbar) => (<ForgotPasswordDialog showNotification={showSnackbar} />)}
    />
  )
}

export default ForgotPasswordForm
