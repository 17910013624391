import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import { useLocation } from 'react-router-dom'
import SecurityQuestionDialog from './SecurityQuestionDialog'

function SecurityQuestionForm({ className, showNotification, ...rest }) {
  const loc = useLocation()
  var userId = loc.state.userId
  var emailAddress = loc.state.emailAddress
  var questionSec = loc.state.questionSec

  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <SecurityQuestionDialog showNotification={showSnackbar }
          userId ={userId} emailAddress ={emailAddress} questionSec = {questionSec}  />
      )}
    />
  )
}

export default SecurityQuestionForm
