import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import {useForm, FormProvider } from 'react-hook-form'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import PasswordUrls from 'src/apiUrls/PasswordUrls'
import ForgotPasswordCustomButtonSection from './ForgotPasswordCustomButtonSection'
import Container from '@material-ui/core/Container'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'

const {
  button: { CngSecondaryButton },
  CngDialog,
  card: {},
  form: {
    field: {
      CngTextField,
      CngSelectField
    },
  },
  table: {},
  CngGridItem,
} = components

function ResetPasswordDialog({ showNotification, userId, emailAddress, questionSec,answerSec }) {
  const history = useHistory()
  const serverDataRef = useRef()
  const [openCustomDialog, setOpenCustomDialog] = useState(true)
  const methods = useForm();
  const url = PasswordUrls.RESET_PASSWORD
  const loc = useLocation()
  var userId = loc.state.userId
  methods.setValue("userId",userId);
  function BackToLogin() {
    history.push('/login')
  }

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userId = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.USER_ID
    )

    let emailAddress = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.EMAIL_ADDRESS
    )

    let securityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION
    )

    let securityAnswer = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_ANSWER
    )

    let newPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.NEW_PASSWORD
    )

    let confirmNewPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CONFIRM_NEW_PASSWORD
    )
    let requestorName = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.REQUESTORNAME
    )

    let resetPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.RESET_PASSWORD
    )

    let helpText = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_5
    )

    let cancel = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CANCEL
    )

    return {
      userId,
      emailAddress,
      securityQuestion,
      securityAnswer,
      newPassword,
      confirmNewPassword,
      resetPassword,
      helpText,
      cancel,
      requestorName
    }
  }

  return (
    <>
      <CngDialog
        dialogContent={
          <FormProvider {...methods} >  
            <form>
              <Container maxWidth={false}>
                <Grid container spacing={1}>
                  <Grid container spacing={3}></Grid>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField name="userId"
                      id="userId"
                      label={ translatedTextsObject.userId + ' *'}
                      disabled={true}
                      value={userId}
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField name="emailAddress"
                      label={ translatedTextsObject.emailAddress + " *"}
                      id="emailAddress"
                      disabled={true}
                      value={emailAddress}
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField name="questionSec"
                      label={ translatedTextsObject.securityQuestion + " *" }
                      id="questionSec"
                      disabled={true}
                      value={questionSec}
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='answerSec'
                      label={ translatedTextsObject.securityAnswer + " *"}
                      disabled={true}
                      value={answerSec}
                      id="answerSec"
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='newPassword'
                      label={ translatedTextsObject.newPassword + " *"}
                      type='password'
                      id="newPassword"
                      inputProps={
                        {maxLength: 24}
                      }
                      helperText={ translatedTextsObject.helpText }
                    />         
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='confirmNewPassword'
                      label={ translatedTextsObject.confirmNewPassword + " *"}
                      type='password'
                      inputProps={
                        {maxLength: 24}
                      }
                      id="confirmNewPassword"
                    />
                  </CngGridItem> 
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField
                      name='requestorName'
                      label={ translatedTextsObject.requestorName + " *"}
                      type='text'
                      inputProps={
                        {maxLength: 12}
                      }
                      id="requestorName"
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    
                  </CngGridItem>  
                  <ForgotPasswordCustomButtonSection buttonLabel={translatedTextsObject.resetPassword} url={url} 
                  data={userId} 
                  isAdd={false} showNotification={showNotification} isResetPage={false} redirect={`/login`}>
                  </ForgotPasswordCustomButtonSection>
                  <CngSecondaryButton onClick={() => { BackToLogin() }}>
                    {translatedTextsObject.cancel}
                  </CngSecondaryButton>      
                </Grid>
              </Container>
            </form>
            </FormProvider>    
        }
        dialogTitle={translatedTextsObject.resetPassword}
        open={openCustomDialog}
        fullWidth
        maxWidth='sm'
      />
    </>
  )
}

ResetPasswordDialog.propTypes = {
  className: PropTypes.string
}

ResetPasswordDialog.defaultProps = {}

export default ResetPasswordDialog
