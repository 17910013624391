import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import {useForm, FormProvider } from 'react-hook-form'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import PasswordUrls from 'src/apiUrls/PasswordUrls'
import Container from '@material-ui/core/Container'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'
import sweetalert from 'sweetalert'
import Axios from 'axios'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  CngDialog,
  card: {},
  form: {
    field: {
      CngTextField,
    },
  },
  table: {},
  CngGridItem,
} = components

function SecurityQuestionDialog({ showNotification, userId, emailAddress, questionSec }) {
  const history = useHistory()
  const serverDataRef = useRef()
  const [openCustomDialog, setOpenCustomDialog] = useState(true)
  const methods = useForm();
  const url = PasswordUrls.VALIDATE_SECURITY_QUESTION

  function BackToLogin() {
    history.push('/login')
  }

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userId = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.USER_ID
    )

    let emailAddress = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.EMAIL_ADDRESS
    )

    let securityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_QUESTION
    )

    let securityAnswer = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.SECURITY_ANSWER
    )

    let newPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.NEW_PASSWORD
    )

    let confirmNewPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CONFIRM_NEW_PASSWORD
    )

    let resetPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.RESET_PASSWORD
    )

    let errorMessage1 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_1
    )

    let errorMessage7 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_7
    )

    let errorMessage8 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_8
    )

    let errorMessage10 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_10
    )

    let helpText = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_5
    )

    let cancel = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.CANCEL
    )

    return {
      userId,
      emailAddress,
      securityQuestion,
      securityAnswer,
      newPassword,
      confirmNewPassword,
      resetPassword,
      helpText,
      errorMessage1,
      errorMessage7,
      errorMessage8,
      errorMessage10,
      cancel
    }
  }

  function securityQuestion(){    
    const answerSec = document.getElementById("answerSec").value
    let errors = false;
    console.log(userId)
    if(userId === undefined || userId === null || userId === ""){
      errors = true;
      sweetalert({
        title: translatedTextsObject.securityQuestion,
        text: translatedTextsObject.errorMessage1,
        icon: "error",
      })
    }
    else if(emailAddress === undefined || emailAddress === null || emailAddress === ""){
      errors = true;
      sweetalert({
        title: translatedTextsObject.securityQuestion,
        text: translatedTextsObject.errorMessage10,
        icon: "error",
      })
    } 
    else if(questionSec === undefined || questionSec === null || questionSec === ""){
      errors = true;
      sweetalert({
        title: translatedTextsObject.securityQuestion,
        text: translatedTextsObject.errorMessage7,
        icon: "error",
      })
    } 
    else if(answerSec === undefined || answerSec === null || answerSec === ""){
      errors = true;
      sweetalert({
        title: translatedTextsObject.securityQuestion,
        text: translatedTextsObject.errorMessage8,
        icon: "error",
      })
    }

    if(errors == false){
      const data = { "userId" : userId, "questionSec": questionSec ,"answerSec" : answerSec }
      const instance = Axios.create({
        withCredentials: false
      });

      instance.post(url, data).then((result) => {
        var data = result.data
        console.log(data)
        console.log(data.status)
        var errorMessages = data.error
        if(data.status === "Success" && data.code==="200" && errorMessages==="You have entered correct Security Answer. Please proceed to do change password.") {
        // if (data.status == 200) {
          history.push({
            pathname: '/forgot/resetpwd',
            state: { userId: userId, emailAddress: emailAddress , questionSec: questionSec, answerSec : answerSec}
          })
        } else {
          sweetalert({
            title: translatedTextsObject.securityQuestion,
            text: errorMessages,
            icon: "error",
          })
        }
      }) 
    } 
  }

  return (
    <>
      <CngDialog
        dialogContent={
          <FormProvider {...methods} >  
            <form>
              <Container maxWidth={false}>
                <Grid container spacing={1}>
                  <Grid container spacing={3}></Grid>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField name="userId"
                      id="userId"
                      label={ translatedTextsObject.userId + ' *'}
                      disabled={true}
                      value={ userId }
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <CngTextField name="emailAddress"
                      label={ translatedTextsObject.emailAddress + " *"}
                      id="emailAddress"
                      disabled={true}
                      value={ emailAddress }
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField name="questionSec"
                      label={ translatedTextsObject.securityQuestion + " *" }
                      id="questionSec"
                      disabled={true}
                      value={ questionSec }
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <CngTextField
                      name='answerSec'
                      label={ translatedTextsObject.securityAnswer + " *"}
                      type='text'
                      inputProps={
                        {maxLength: 50}
                      }
                      id="answerSec"
                    />
                  </CngGridItem>  
                  <CngPrimaryButton onClick={() => {
                    securityQuestion()
                  }}>
                    Verify Security Question
                  </CngPrimaryButton>
                  <CngSecondaryButton style={{marginLeft: '5px'}} onClick={() => { BackToLogin() }}>
                    {translatedTextsObject.cancel}
                  </CngSecondaryButton>      
                </Grid>
              </Container>
            </form>
            </FormProvider>    
        }
        dialogTitle={translatedTextsObject.securityQuestion}
        open={openCustomDialog}
        fullWidth
        maxWidth='sm'
      />
    </>
  )
}

SecurityQuestionDialog.propTypes = {
  className: PropTypes.string
}

SecurityQuestionDialog.defaultProps = {}

export default SecurityQuestionDialog
