import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import { PartyMasterLookupsContext } from '../sbci/lookups/PartyMasterLookupsContext'
import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import PartyMasterKeys from '../../constants/locale/key/PartyMaster'
import Namespace from '../../constants/locale/Namespace'
import CngField from '../../components/cngcomponents/CngField'
import CngSubSection from '../../components/cngcomponents/CngSubSection'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

function PartyMasterViewContent(props) {
  const { data } = props

  const { getLookupValue } = useContext(PartyMasterLookupsContext)
  const { translate } = useTranslation(Namespace.PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const partyMaster = translate(Namespace.PARTY_MASTER, PartyMasterKeys.TITLE)
    const partyidn = translate(Namespace.PARTY_MASTER, PartyMasterKeys.PARTYIDN)
    const partytype = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYTYPE
    )
    const partyname1 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME1
    )
    const partyname2 = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PARTYNAME2
    )
    const address1 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS1)
    const address2 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS2)
    const address3 = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ADDRESS3)
    const cityname = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CITYNAME)
    const statecode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.STATECODE
    )
    const postalcode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.POSTALCODE
    )
    const countrycode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.COUNTRYCODE
    )
    const contactperson = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.CONTACTPERSON
    )
    const telephonenumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.TELEPHONENUMBER
    )
    const active = translate(Namespace.PARTY_MASTER, PartyMasterKeys.ACTIVE)
    const dba = translate(Namespace.PARTY_MASTER, PartyMasterKeys.DBA)
    const email = translate(Namespace.PARTY_MASTER, PartyMasterKeys.EMAIL)
    const crNo = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CRNO)
    const faxNo = translate(Namespace.PARTY_MASTER, PartyMasterKeys.FAXNo)
    const division = translate(Namespace.PARTY_MASTER, PartyMasterKeys.DIVISION)
    const locPortCode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.LOCPORTCODE
    )
    const poBoxNumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.POBOXNUMBER
    )
    const ctpatInd = translate(Namespace.PARTY_MASTER, PartyMasterKeys.CAPATIND)
    const partyIdentifier = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.TITLE
    )
    const partyIdentifierCode = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.PARTYIDENTIFIERCODE
    )
    const partyIdentifierNumber = translate(
      Namespace.PARTY_MASTER,
      PartyMasterKeys.PartyIdentifier.PARTYIDENTIFIER
    )

    return {
      partyMaster,
      partyidn,
      partytype,
      partyname1,
      partyname2,
      address1,
      address2,
      address3,
      cityname,
      statecode,
      postalcode,
      countrycode,
      contactperson,
      telephonenumber,
      active,
      dba,
      email,
      crNo,
      faxNo,
      division,
      locPortCode,
      poBoxNumber,
      ctpatInd,
      partyIdentifier,
      partyIdentifierCode,
      partyIdentifierNumber
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box px={2} py={1} bgcolor='grey.200'>
          <Typography style={{ fontWeight: 600 }}>
            {translatedTextsObject.partyMaster}
          </Typography>
        </Box>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CngField label={translatedTextsObject.partytype}>
                {!_.isEmpty(data.partytype) && (
                  <Grid container spacing={1}>
                    {data.partytype.map((partyType) => (
                      <Grid key={partyType} item xs='auto'>
                        <Chip label={getLookupValue('partyType', partyType)} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.partyidn}>
                {data.partyidn}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.partyname1}>
                {data.partyname1}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.partyname2}>
                {data.partyname2}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.dba}>{data.dba}</CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address1}>
                {data.address1}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address2}>
                {data.address2}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address3}>
                {data.address3}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.cityname}>
                {data.cityname}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.countrycode}>
                {getLookupValue('countrycode', data.countrycode)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.statecode}>
                {data.statecode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.postalcode}>
                {data.postalcode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.crNo}>
                {data.crNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.contactperson}>
                {data.contactperson}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.telephonenumber}>
                {data.telephonenumber}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.faxNo}>
                {data.faxNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.email}>
                {data.email}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.division}>
                {data.division}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.locPortCode}>
                {data.locPortCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.poBoxNumber}>
                {data.poBoxNumber}
              </CngField>
            </Grid>
            <Grid item xs={12}>
              <CngSubSection title='Other Details'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                      <FontAwesomeIcon
                        color={
                          data.ctpatInd === 'Y'
                            ? theme.palette.primary.main
                            : theme.palette.error.main
                        }
                        icon={data.ctpatInd === 'Y' ? faCheckCircle : faBan}
                      />
                      <Typography variant='body2'>CTPAT member</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CngField label={translatedTextsObject.active}>
                      <Typography
                        variant='body2'
                        style={{
                          color: data.active
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }}
                      >
                        {data.active ? 'Active' : 'Inactive'}
                      </Typography>
                    </CngField>
                  </Grid>
                </Grid>
              </CngSubSection>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2} py={1} bgcolor='grey.200'>
          <Typography style={{ fontWeight: 600 }}>
            {translatedTextsObject.partyIdentifier}
          </Typography>
        </Box>
        <Box padding={2}>
          {!_.isEmpty(data.partyIdentifier) ? (
            <Card variant='outlined'>
              <Grid container>
                {data.partyIdentifier.map((identifier, index) => (
                  <React.Fragment key={identifier.id || index}>
                    <Grid item xs={12}>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.partyIdentifierCode}>
                              {getLookupValue('partyIdentifierCode', identifier.partyIdentifierCode)}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.partyIdentifierNumber}>
                              {identifier.partyIdentifierNumber}
                            </CngField>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {index + 1 < data.partyIdentifier.length && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Card>
          ) : (
            <Typography variant='body2'>No records to display</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default PartyMasterViewContent
