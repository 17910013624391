import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import { useLocation } from 'react-router-dom'
import ResetPasswordDialog from './ResetPasswordDialog'

function ResetPasswordForm({ className, showNotification, ...rest }) {
  const loc = useLocation()
  var userId = loc.state.userId
  var emailAddress = loc.state.emailAddress
  console.log("Question section"+loc.state.questionSec);
  var questionSec = loc.state.questionSec
  console.log("answer section"+loc.state.answerSec);
  var answerSec=loc.state.answerSec

  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <ResetPasswordDialog showNotification={showSnackbar }
          userId ={userId} emailAddress ={emailAddress} questionSec = {questionSec} answerSec = {answerSec} />
      )}
    />
  )
}

export default ResetPasswordForm
