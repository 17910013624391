import PartyMasterApiUrls from '../../apiUrls/PartyMasterApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import pathMap from '../../paths/pathMap'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const { history, onSetLoading, showNotification } = props
  const { id } = useParams()

  return (
    <Card>
      <Box p={2}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{ url: PartyMasterApiUrls.GET }}
          update={{ url: PartyMasterApiUrls.PUT }}
          id={id}
          renderButtonSection={(_, loading) => (
            <PartyMasterEditPageButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function PartyMasterEditPageButtonSection(props) {
  const { history, onSetLoading, loading } = props

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <CngButton
            color='primary'
            disabled={loading}
            type='submit'
            size='medium'
          >
            Update
          </CngButton>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.PARTY_MASTER_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
