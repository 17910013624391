import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './PartyIdentifierFormProperties'
import PartyIdentifierFieldsPlaceholder from './PartyIdentifierFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function PartyIdentifierSection(props) {
  const { partyIdentifier = [] } = props

  const generatedIndex = useRef(0)
  const { getValues, setValue } = useFormContext()

  function handleAddPartyIdentifier(data) {
    const partyIdentifier = [...getValues('partyIdentifier'), data]

    setValue('partyIdentifier', partyIdentifier, { shouldDirty: true })
  }

  function handleDeletePartyIdentifier(index) {
    const partyIdentifier = [...getValues('partyIdentifier')]

    partyIdentifier.splice(index, 1)

    setValue('partyIdentifier', partyIdentifier, { shouldDirty: true })
  }

  function handleEditPartyIdentifier(index, data) {
    const partyIdentifier = [...getValues('partyIdentifier')]

    partyIdentifier[index] = data

    setValue('partyIdentifier', partyIdentifier, { shouldDirty: true })
  }

  return (
    <Grid container spacing={1}>
      {_.isEmpty(partyIdentifier) ? (
        <Grid item xs={12}>
          <PartyIdentifierFieldsPlaceholder onAdd={handleAddPartyIdentifier} />
        </Grid>
      ) : (
        partyIdentifier.map((detail, index) => (
          <React.Fragment key={++generatedIndex.current}>
            <Grid item xs={12}>
              <PartyIdentifierEntry
                partyIdentifier={detail}
                onAdd={handleAddPartyIdentifier}
                onDelete={() => handleDeletePartyIdentifier(index)}
                onEdit={(data) => handleEditPartyIdentifier(index, data)}
              />
            </Grid>
            {index + 1 !== partyIdentifier.length && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))
      )}
    </Grid>
  )
}

function PartyIdentifierEntry(props) {
  const { partyIdentifier, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: partyIdentifier })
  const {
    formState: { isDirty }
  } = methods

  function handleEditPartyIdentifier(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isDirty ? (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={() => methods.reset()}
                      size='medium'
                    >
                      Reset
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      onClick={methods.handleSubmit(
                        handleEditPartyIdentifier
                      )}
                      size='medium'
                    >
                      Save changes
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() =>
                        onAdd({ ...partyIdentifier, id: undefined })
                      }
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(partyIdentifier)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default PartyIdentifierSection
