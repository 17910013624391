import { components, useTranslation } from 'cng-web-lib'
import {useFormContext,useForm} from 'react-hook-form'
import React, { useState } from 'react'
import Axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import sweetalert from 'sweetalert'
import Namespace from 'src/constants/locale/Namespace'
import UserNamePasswordKeys from '../../constants/locale/key/UserNamePassword'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  form: {
    adapter: { 
      useFormAdapter:{ useField } 
    },
  },
  CngGridItem, CngDialog
} = components

function ForgotPasswordCustomButtonSection({ buttonLabel, url, data, isAdd,isResetPage, showNotification, redirect }) {
  //Get the current modified form data using Formik
  const { getValues, trigger, formState: { isSubmitting,errors }} = useFormContext()
  var values = getValues()
  console.log("ForgotPasswordCustomButtonSection" +JSON.stringify(values));
  // if (!isAdd) {
  //   //Get the saved form Data from Server
  //   var serverData = data;
  //   console.log("Server Data: "+JSON.stringify(data));
  //   //Copy the modified data in form to Server Data - this ensures ID and other basic information is copied
  //   values = { ...serverData, ...values }
  // }

  const [openDialog, setOpenDialog] = useState()
  const history = useHistory()
  const { id } = useParams()
  const loc = useLocation();
  let screen = loc.pathname.toString();
  let validationErrors = false;

  const { translate } = useTranslation([Namespace.USER_NAME_PASSWORD])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    
    let getSecurityQuestion = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.GET_SECURITY_QUESTION
    )

    let resetPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.RESET_PASSWORD
    )

    let forgotPassword = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.FORGOT_PASSWORD
    )

    let errorMessage1 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_1
    )

    let errorMessage3 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_3
    )

    let errorMessage4 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_4
    )

    let errorMessage5 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_5
    )

    let errorMessage7 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_7
    )

    let errorMessage8 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_8
    )

    let errorMessage10 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_10
    )

    let errorMessage11 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_11
    )

    let errorMessage12 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_12
    )

    let errorMessage13 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_13
    )

    let errorMessage14 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_14
    )

    let errorMessage16 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_16
    )

    let errorMessage17 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.ERROR_MESSAGE_17
    )

    let helpText6 = translate(
      Namespace.USER_NAME_PASSWORD,
      UserNamePasswordKeys.HELP_TEXT_6
    )

    return {
      getSecurityQuestion,
      resetPassword,
      forgotPassword,
      errorMessage1,
      errorMessage3,
      errorMessage4,
      errorMessage5,
      errorMessage7,
      errorMessage8,
      errorMessage10,
      errorMessage11,
      errorMessage12,
      errorMessage13,
      errorMessage14,
      errorMessage16,
      errorMessage17,
      helpText6
    }
  }

  return (
    <CngGridItem>
      <CngPrimaryButton disabled={isSubmitting} isSubmitting={isSubmitting} onClick={() => {
        if(screen !== "/forgot/resetpwd"){
          validationErrors = false
          let userId = document.getElementById('userId').value;
          let emailAddress = document.getElementById('emailAddress').value;
          let regex = /\S+@\S+\.\S+/;
          if(userId == null || userId == ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.getSecurityQuestion,
              text: translatedTextsObject.errorMessage1,
              icon: "error",
            })
          }
          else if(emailAddress == null || emailAddress == ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.getSecurityQuestion,
              text: translatedTextsObject.errorMessage10,
              icon: "error",
            })
          } 
          else if(emailAddress != null || emailAddress != "") {
            let validAddress = regex.test(emailAddress);
            if(!validAddress){
              validationErrors = true;
              sweetalert({
                title: translatedTextsObject.getSecurityQuestion,
                text: translatedTextsObject.errorMessage11,
                icon: "error",
              })
            }
          }
           
          if(!validationErrors){
            setOpenDialog(true)
          }
        } else {
          validationErrors = false
          let userIdValue = document.getElementById('userId').value;
          let emailAddressValue = document.getElementById('emailAddress').value;
          let questionSecValue = document.getElementById('questionSec').value;
          let answerSecValue = document.getElementById('answerSec').value;
          let newPasswordValue = document.getElementById('newPassword').value;
          let confirmNewPasswordValue = document.getElementById('confirmNewPassword').value;
          if(userIdValue == undefined || userIdValue == null || userIdValue == ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage1,
              icon: "error",
            })
          }
          else if(emailAddressValue == undefined || emailAddressValue == null || emailAddressValue == ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage10,
              icon: "error",
            })
          } 
          else if(questionSecValue === undefined || questionSecValue === null || questionSecValue === ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage7,
              icon: "error",
            })
          } 
          else if(answerSecValue === undefined || answerSecValue === null || answerSecValue === ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage8,
              icon: "error",
            })
          } 
          else if(newPasswordValue === undefined || newPasswordValue === null || newPasswordValue === ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage3,
              icon: "error",
            })
          } 
          else if(newPasswordValue !== undefined && newPasswordValue !== null && newPasswordValue !== "" && newPasswordValue.length < 12  && newPasswordValue.length > 24){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage16,
              icon: "error",
            })
          } 
          else if(confirmNewPasswordValue === undefined || confirmNewPasswordValue === null || confirmNewPasswordValue === ""){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage4,
              icon: "error",
            })
          } 
          else if(confirmNewPasswordValue !== undefined && confirmNewPasswordValue !== null && confirmNewPasswordValue !== "" && confirmNewPasswordValue.length < 12 && confirmNewPasswordValue.length > 24){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage17,
              icon: "error",
            })
          } 
          else if(newPasswordValue !== undefined && newPasswordValue !== null && newPasswordValue !== "" && confirmNewPasswordValue !== undefined && confirmNewPasswordValue !== null && confirmNewPasswordValue !== "" && newPasswordValue !== confirmNewPasswordValue){
            validationErrors = true;
            sweetalert({
              title: translatedTextsObject.resetPassword,
              text: translatedTextsObject.errorMessage5,
              icon: "error",
            })
          } 

          if(!validationErrors){
            setOpenDialog(true)
          }
        }
      }} >
        {buttonLabel}
      </CngPrimaryButton>
      <CngDialog
        dialogAction={
          <>
            <CngSecondaryButton onClick={() => setOpenDialog(false)}>
              Close
            </CngSecondaryButton>
            <CngPrimaryButton onClick={() => {
              const instance = Axios.create({
                withCredentials: false
              });
              instance.post(url, values).then((result) => {
                var data = result.data
                var errorMessages = data.errorMessages
				        var error = data.error
                if(error==="User ID and Email Address are valid. Please proceed to enter Secret Security Answer."){
                  sweetalert({
                    title: translatedTextsObject.getSecurityQuestion,
                    text: translatedTextsObject.errorMessage12,
                    icon: "error",
                  }).then(() => {
                    history.push('/login')
                  })
                }
                else if(error==="You cannot reuse passwords which have been previously used.")
                {
                  sweetalert({
                    title: translatedTextsObject.resetPassword,
                    text: error,
                    icon: "error",
                  }).then(() => {
                    history.push('/login')
                  })
                }
                else if(error==="Invalid UserID / Password" && data.status==="fail")
                {
                  sweetalert({
                    title: translatedTextsObject.forgotPassword,
                    text: translatedTextsObject.errorMessage13,
                    icon: "error",
                  }).then(() => {
                    history.push('/login')
                  })
                }
                else if(data.status==="fail")
                {
                  sweetalert({
                    title: translatedTextsObject.resetPassword,
                    text: error,
                    icon: "error",
                  }).then(() => {
                    history.push('/login')
                  })
                }
                else if (result.status == 200 && data != null && errorMessages == null) {
				          if (error ===  "Reset Password has been done successfully. Please login with new password.")
                     showNotification("success", translatedTextsObject.errorMessage14)
                  if(!isResetPage){
                    history.push({
                      pathname: redirect,
                      state: { userId: values.userId, emailAddress:values.emailAddress , questionSec:data.error }
                    })
                  }
                  else {
                    history.push(redirect)
                  }
                  } else {
                    showNotification("error", errorMessages)
                  }
              },);
              setOpenDialog(false);
            }}>
              {buttonLabel}
            </CngPrimaryButton>
          </>
        }
        dialogContent={translatedTextsObject.helpText6}
        dialogTitle={translatedTextsObject.forgotPassword}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </CngGridItem>
  )
}
export default ForgotPasswordCustomButtonSection
