import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import PartyConfigApiUrls from 'src/apiUrls/PartyConfigApiUrls'
import React, {useState, useRef, useEffect} from 'react'
import PartyConfigKeys from 'src/constants/locale/key/PartyConfig'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import Table from '../../components/aciacehighway/Table'
import TprApiUrls  from 'src/apiUrls/TprApiUrls'
import TinyChip from '../../components/aciacehighway/TinyChip'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const {
  filter: { BETWEEN, EQUAL, IN, LIKE,NOT_EQUAL }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const tableRef = useRef(null)

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.PARTY_CONFIG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, partyConfig: null })

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        TprApiUrls.GET,
        {
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.id] = item.name
          })

          return result
        }
      )
    ]).then(([partyId]) => {
      setLookups({
        partyId
      })
    }
    )
  },[]);

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleDeletePartyConfig() {
    if (confirmDialog.partyConfig) {
      deleteRecord.execute(
        PartyConfigApiUrls.DELETE,
        confirmDialog.partyConfig,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, partyConfig: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function makeTranslatedTextsObject() {
    let partyConfig = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: partyConfig
      }
    )
    let partyId = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.PARTY_ID
    )
    let module = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.MODULE
    )
    let filterByUserFlag = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.FILTER_BY_USER_FLAG
    )

    let deleteButton = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.DELETE_BUTTON
    )

    let editButton = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.EDIT_BUTTON
    )

    let createButton = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.CREATE_BUTTON
    )

    let deleteMessage = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.DELETE_MESSAGE
    )

    let deleteConfirmMessage = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.DELETE_CONFIRM_MESSAGE
    )

    let deleteCancelMessage = translate(
      Namespace.PARTY_CONFIG,
      PartyConfigKeys.DELETE_CANCEL_MESSAGE
    )

    return {
      partyConfig,
      tableTitle,
      partyId,
      module,
      filterByUserFlag,
      deleteButton,
      editButton,
      createButton,
      deleteMessage,
      deleteConfirmMessage,
      deleteCancelMessage
    }
  }

  const columns = [
    {
      field: "partyIdString",
      sortKey: "partyId",
      title: translatedTextsObject.partyId,
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyId', data.partyId)}
          <TinyChip label={data.partyId} variant='outlined' />
        </Typography>
    },
    {
      field: "module",
      sortKey: "module",
      title: translatedTextsObject.module,
    },
    {
      field: "filterByUserFlag",
      sortKey: "filterByUserFlag",
      title: translatedTextsObject.filterByUserFlag
    }
  ]

  const filters = [
    {
      name: "partyIdString",
      label: translatedTextsObject.partyId,
      type: 'textfield',
      operatorType: EQUAL
    },
    {
      name: "module",
      label: translatedTextsObject.module,
      type: 'checkbox',
      operatorType: IN,
      options: [
        {
          label: 'ACI Ocean',
          value: 'ACIOCN',
          filterValue: {
            value: 'ACIOCN'
          }
        },
        {
          label: 'EHBL',
          value: 'EHBL',
          filterValue: {
            value: 'EHBL'
          }
        }
      ]
    },
    {
      name: "filterByUserFlag",
      label: translatedTextsObject.filterByUserFlag,
      type: 'textfield',
      operatorType: EQUAL
    }
  ]

  return (
    <>
    <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.PARTY_CONFIG_ADD_VIEW)
            },
            label: translatedTextsObject.createButton
          },
        ]}
        columns={columns}
        compact
        //exportData={{ url: PartyConfigApiUrls.EXPORT }}
        fetch={{ url: PartyConfigApiUrls.GET }}
        fetchFilters={[

        ]}
        filters={filters}
        onRowClick={(rowData) => {
          history.push(`${location.pathname}/edit/${rowData.id}`)
        }
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
          },
          {
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (data) => setConfirmDialog({ open: true, partyConfig : data }),
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, partyConfig: null })}
        confirmDialog={handleDeletePartyConfig}
        content={translatedTextsObject.deleteMessage}
        okMsg={translatedTextsObject.deleteConfirmMessage}
        cancelMsg={translatedTextsObject.deleteCancelMessage}
        title={translatedTextsObject.deleteButton}
      />
    </>
    /*<Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: PartyConfigApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: PartyConfigApiUrls.EXPORT }}
              fetch={{ url: PartyConfigApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>*/
  )
}

export default TablePage
